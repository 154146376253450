import { PayloadAction } from "@reduxjs/toolkit";
import { setUser } from "@sentry/react";

import { JwtClaimData } from "@thekeytechnology/academies-lib-webapp";
import { decodeToken } from "react-jwt";
import { AuthState, JwtTokenData } from "@slices/auth/auth.types";
import {
	removeParsedLoginData,
	removeParsedUserData,
	updateParsedLoginData,
	updateParsedUserData,
} from "@slices/auth/auth.utils";

const setSentryUser = (tokenData?: JwtTokenData) => {
	if (!tokenData) {
		setUser(null);
		return;
	}
	const decodedToken = decodeToken<JwtClaimData>(tokenData.accessToken);

	if (!decodedToken) {
		return;
	}
	setUser({
		accountId: decodedToken.accountId,
		userId: decodedToken.userId,
	});
};

export const setLoggedIn = (
	state: AuthState,
	action: PayloadAction<{
		tokenData: JwtTokenData;
		firstLogin?: boolean;
		isAvgsClient?: boolean;
		redirectPath?: string;
	}>,
) => {
	state.isLoggedIn = true;
	state.loginData = action.payload.tokenData;
	state.firstLogin = action.payload.firstLogin ?? false;
	state.isAvgsClient = action.payload.isAvgsClient ?? false;
	state.redirectPath = action.payload.redirectPath;
	updateParsedLoginData(action.payload.tokenData);
	updateParsedUserData({ isAvgsClient: state.isAvgsClient });
	setSentryUser(action.payload.tokenData);
};

export const refreshLogin = (
	state: AuthState,
	action: PayloadAction<{ loginData: JwtTokenData }>,
) => {
	state.isLoggedIn = true;
	state.loginData = action.payload.loginData;
	updateParsedLoginData(action.payload.loginData);
	setSentryUser(action.payload.loginData);
};

export const logout = (state: AuthState) => {
	state.isLoggedIn = false;
	state.loginData = undefined;
	state.firstLogin = false;
	state.isAvgsClient = false;
	state.redirectPath = undefined;
	setSentryUser();
	removeParsedLoginData();
	removeParsedUserData();
};

export const setRedirectAfterLoginUrl = (
	state: AuthState,
	action: PayloadAction<string | undefined>,
) => {
	state.redirectPath = action.payload;
	updateParsedUserData({ redirectPath: action.payload });
};
