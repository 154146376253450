import * as Sentry from "@sentry/react";

import { ThemeProvider } from "@thekeytechnology/epic-ui";
import { addLocale as primereactAddLocale, locale as primereactSetLocale } from "primereact/api";
import { useRef } from "react";
import { CookiesProvider } from "react-cookie";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { Environment, RelayEnvironmentProvider } from "react-relay";
import { useToast } from "@hooks/useToast";
import primeReactLocale from "@i18n/primereact-locale.json";
import { PermissionBasedNavigation } from "@navigation/permission-based-navigation";
import { Routes } from "@router/routes";
import { ErrorFallbackComponent } from "@screens/error-fallback";
import { LightTheme } from "@themes/light-theme";
import { createRelayEnvironment } from "./RelayEnvironment";
import "@i18n/i18n";
import packageJson from "../package.json";

// needed for proper typing theme
const LogoComponent = () => null;
const LogoSquareComponent = () => null;
if (
	process.env.REACT_APP_APP_ENVIRONMENT === "production" ||
	(process.env.REACT_APP_APP_ENVIRONMENT === "staging" &&
		process.env.REACT_APP_API_BASE === "https://staging.api.constellation.academy")
) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_APP_ENVIRONMENT,
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		tracesSampleRate: 1.0,
		release: process.env.REACT_APP_SENTRY_PROJECT_NAME + "@" + packageJson?.version,
	});
}

primereactAddLocale("de", primeReactLocale.de);
primereactSetLocale("de");

function App() {
	const { showError } = useToast();
	const { t } = useTranslation();

	const handleOnError = (errorMessage: string) => {
		showError({
			summary: "Error",
			detail: errorMessage,
		});
	};

	const relayEnvironmentRef = useRef(createRelayEnvironment(handleOnError, t) as Environment);
	return (
		<ThemeProvider theme={{ ...LightTheme, LogoComponent, LogoSquareComponent }}>
			<Sentry.ErrorBoundary fallback={<ErrorFallbackComponent />}>
				<RelayEnvironmentProvider environment={relayEnvironmentRef.current}>
					<CookiesProvider>
						<DndProvider backend={HTML5Backend}>
							<PermissionBasedNavigation routes={Routes} />
						</DndProvider>
					</CookiesProvider>
				</RelayEnvironmentProvider>
			</Sentry.ErrorBoundary>
		</ThemeProvider>
	);
}

export default App;

export interface Tuple<T, K> {
	key: T;
	value: K;
}

declare global {
	interface Array<T> {
		distinct(): Array<T>;

		distinctBy<K>(key: (i: T) => K): Array<T>;

		groupBy<K>(key: (i: T) => K): Tuple<K, T[]>[];
	}
}

// eslint-disable-next-line no-extend-native
Array.prototype.distinct = function () {
	return this.filter((x, i) => i === this.indexOf(x));
};

// eslint-disable-next-line no-extend-native
Array.prototype.distinctBy = function <T, K>(keyFunction: (i: T) => K) {
	const knownKeys: K[] = [];
	return this.filter((x) => {
		const key = keyFunction(x);
		if (knownKeys.includes(key)) {
			return false;
		} else {
			knownKeys.push(key);
			return true;
		}
	});
};

// eslint-disable-next-line no-extend-native
Array.prototype.groupBy = function <T, K>(keyFunction: (i: T) => K): Tuple<K, T[]>[] {
	const groups: Tuple<K, T[]>[] = [];

	this.forEach((x) => {
		const keyValue = keyFunction(x);
		const existingGroup = groups.find((g) => g.key === keyValue);
		if (existingGroup) {
			existingGroup.value.push(x);
		} else {
			groups.push({ key: keyValue, value: [x] });
		}
	});
	return groups;
};
